import React from "react";
import { Outlet } from "react-router-dom";

import { GdriveCredsContainer } from "~/blocks/gdrive/credentials";

function EndCardConceptsRoot() {
  return (
    <GdriveCredsContainer>
      <Outlet />
    </GdriveCredsContainer>
  );
}

export default EndCardConceptsRoot;
