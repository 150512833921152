import React from "react";
import { Button, Row, Col } from "react-bootstrap";

import Spinner from "~/components/spinner";
import { getGdriveCredentials } from "~/constants";
import { loadScript, useUserData } from "~/utils";

function GdriveCredsContainer({ children }) {
  const hasRefreshToken = useUserData().has_refresh_token;
  const [loading, setLoading] = React.useState(true);
  const [refreshExist] = React.useState(hasRefreshToken);
  const triggerAuth = React.useCallback(() => {
    const client = window.google.accounts.oauth2.initCodeClient({
      ...getGdriveCredentials(),
      ux_mode: "redirect",
      include_granted_scopes: false,
      state: window.location.pathname,
    });
    client.requestCode();
  }, []);
  const context = React.useMemo(
    () => ({ loading, refreshExist, triggerAuth }),
    [loading, refreshExist, triggerAuth]
  );

  React.useEffect(() => {
    loadScript("https://accounts.google.com/gsi/client").then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <gdriveCredsContext.Provider value={context}>
      {children}
    </gdriveCredsContext.Provider>
  );
}

function GdriveCredsGuard({ children }) {
  const { loading, refreshExist, triggerAuth } =
    React.useContext(gdriveCredsContext);

  if (loading) return <Spinner />;
  if (!refreshExist) {
    return (
      <>
        <Row className="justify-content-center mt-5">
          <Col xs={12} md={8}>
            <p className="text-center">
              Please authenticate your TDS Google Account to allow connection
              with Google Drive and processing of the creative link.
            </p>
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <Button onClick={triggerAuth}>Google Authenticate</Button>
        </div>
      </>
    );
  }
  if (typeof children === "function") {
    return children(refreshExist);
  }
  return (
    <tokenContext.Provider value={refreshExist}>
      {children}
    </tokenContext.Provider>
  );
}

function useGdriveToken() {
  return React.useContext(tokenContext);
}

const gdriveCredsContext = React.createContext();
const tokenContext = React.createContext();

export {
  GdriveCredsContainer,
  GdriveCredsGuard,
  gdriveCredsContext,
  useGdriveToken,
};
