function sortAssets(array, creative) {
  // sort assets by creative number, moving assets with given creative to the head of list
  const sortedArray = array.sort((a, b) => b.creative - a.creative);
  const creativeElements = sortedArray.filter(
    (item) => item.creative === creative
  );
  const otherElements = sortedArray.filter(
    (item) => item.creative !== creative
  );
  return creativeElements.concat(otherElements);
}

function assetToOptions(assets) {
  return assets.map((asset) => ({
    label: asset.long_name,
    value: asset.id,
  }));
}

export { sortAssets, assetToOptions };
