import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { Button, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLoaderData, defer } from "react-router-dom";

import { useAlert } from "~/base/alerts";
import {
  filtersLoader,
  PageFiltersForm,
} from "~/blocks/creatives/jobs/filters";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import { SmallSpinner } from "~/components/spinner";
import { axios, extractSearch } from "~/utils";
import "./jobs.scss";
import { useInterval } from "~/utils/time";

function JobsPage() {
  const { jobs, filtersPromise } = useLoaderData();
  const [jobsData, setJobsData] = React.useState(jobs);
  const pollJobsData = async () => {
    const search = new URLSearchParams(window.location.search).toString();
    const pollUrl = search ? `jobs?${search}` : "jobs";
    const response = await axios(pollUrl);
    const jsonData = await response.data;
    setJobsData(jsonData);
  };
  useInterval(pollJobsData, 5000);

  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
        filterAlwaysOpened
      />
      <JobsTable data={jobsData} refreshPage={pollJobsData} />
    </>
  );
}

JobsPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const response = await axios(`jobs${search}`);
  const filtersPromise = filtersLoader();

  return defer({
    jobs: response.data,
    filtersPromise,
  });
};

function FailedJobStatus() {
  const tooltip = (
    <Tooltip className="failed-job-tooltip">
      Error uploading the creative. Please try again or contact Marketing and
      Data Products team
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="bottom" overlay={tooltip}>
      <FontAwesomeIcon icon={faCircleXmark} className="failed-job-status" />
    </OverlayTrigger>
  );
}

function JobStatus({ status }) {
  if (status === "failed") {
    return <FailedJobStatus />;
  }
  if (status === "success") {
    return (
      <FontAwesomeIcon icon={faCircleCheck} className="success-job-status" />
    );
  }
  if (["in_queue", "in_progress"].includes(status)) {
    return <SmallSpinner />;
  }
  return <span>{status}</span>;
}

function JobsTable({ data, refreshPage }) {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">User</th>
            <th scope="col">Creative #</th>
            <th scope="col">App</th>
            <th scope="col">Creative Asset</th>
            <th scope="col">Duration</th>
            <th scope="col">Resolution</th>
            <th scope="col">Language</th>
            <th scope="col">Time</th>
            <th scope="col">Google Drive</th>
            <th scope="col">FB Ads Manager</th>
            <th scope="col">Google Ads</th>
            <th scope="col">Management</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((job) => (
            <tr key={job.id}>
              <td>
                <p>{job.id}</p>
              </td>
              <td>{job.author}</td>
              <td>
                <p>
                  <Link to={`/creatives/concepts/${job.creative_id}/assets`}>
                    {job.creative_number}
                  </Link>
                </p>
              </td>
              <td>{job.application_code}</td>
              <td>
                <p>{job.long_name}</p>
              </td>
              <td>{job.duration}</td>
              <td>{job.resolution}</td>
              <td>{job.language}</td>
              <td>
                {moment.utc(job.created_at).format("MMMM D, YYYY HH:mm:ss")}
              </td>
              <td>
                <JobStatus status={job.gdrive_status} />
              </td>
              <td>
                <JobStatus status={job.facebook_status} />
              </td>
              <td>
                <JobStatus status={job.google_ads_status} />
              </td>
              <td>
                <div className="d-flex flex-row">
                  <FailButton job={job} refreshPage={refreshPage} />
                  <RetryButton job={job} refreshPage={refreshPage} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

function RetryButton({ job, refreshPage }) {
  const addAlert = useAlert();
  const retryJob = () =>
    axios({
      method: "POST",
      url: `jobs/${job.id}/retry/`,
    })
      .then(() => {
        refreshPage();
        addAlert("Triggered failed task to retry", "info");
      })
      .catch(() => {
        addAlert("Error on re-trying job", "error");
      });
  const disabled = job.status !== "failed";
  return (
    <Button disabled={disabled} variant="info" size="sm" onClick={retryJob}>
      Retry
    </Button>
  );
}

function FailButton({ job, refreshPage }) {
  const addAlert = useAlert();
  const failJob = () =>
    axios({
      method: "POST",
      url: `jobs/${job.id}/fail/`,
    })
      .then(() => {
        refreshPage();
        addAlert("Marked running tasks as failed", "info");
      })
      .catch(() => {
        addAlert("Error on failing job tasks", "error");
      });
  const disabled = ![
    job.google_ads_status,
    job.facebook_status,
    job.gdrive_status,
  ].includes("in_progress");
  return (
    <Button
      className="mb-0"
      disabled={disabled}
      variant="danger"
      size="sm"
      onClick={failJob}
    >
      Mark failed
    </Button>
  );
}

export default JobsPage;
