import React from "react";
import BBreadcrumb from "react-bootstrap/Breadcrumb";
import { useMatches, Link } from "react-router-dom";

function Breadcrumb() {
  const [parents, current] = useBreadcrumb();
  if (!current) return null;
  return (
    <BBreadcrumb>
      {parents.map(({ to, label }) => (
        <BBreadcrumb.Item linkAs="span" key={to}>
          <Link to={to}>{label}</Link>
        </BBreadcrumb.Item>
      ))}
      <BBreadcrumb.Item className="text-dark" active>
        {current}
      </BBreadcrumb.Item>
    </BBreadcrumb>
  );
}

function useBreadcrumb() {
  const matches = useMatches();
  const crumbData = getCrumbData(matches);
  let parents = matches.filter((e) => e.handle && e.handle.crumb);
  parents = parents.map((e) => ({
    to: e.pathname,
    label: getLabel(e.handle.crumb, crumbData),
  }));
  let current = parents.pop();
  if (!current) return [[], null];
  current = current.label;
  return [parents, current];
}

function getCrumbData(matches) {
  return matches
    .filter((e) => e.handle && e.handle.getCrumbData)
    .map((e) => e.handle.getCrumbData(e.data))
    .reduce((acc, current) => ({ ...acc, ...current }), {});
}

function getLabel(crumb, crumbData) {
  if (typeof crumb === "function") return crumb(crumbData);
  return crumb;
}

function getСreativeBreadCrumbName(asset, nameAttributes) {
  const name = [];
  nameAttributes.forEach((attr) => {
    if (asset[attr]) {
      name.push(asset[attr]);
    }
  });
  return name.join(" ");
}

export default Breadcrumb;
export { getСreativeBreadCrumbName };
