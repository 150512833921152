import React from "react";
import { Outlet } from "react-router-dom";

import {
  GdriveCredsContainer,
  GdriveCredsGuard,
} from "~/blocks/gdrive/credentials";

function PlayableConceptsRoot() {
  return (
    <GdriveCredsContainer>
      <GdriveCredsGuard>
        <Outlet />
      </GdriveCredsGuard>
    </GdriveCredsContainer>
  );
}

export default PlayableConceptsRoot;
