import React from "react";
import { Row, Col } from "react-bootstrap";

import { ResetButton, FilterCol } from "~/components/filters";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { useFilters } from "~/components/page-header";
import { cachedAxios, useSearchParams } from "~/utils";

async function filtersLoader() {
  const response = await cachedAxios("/creative-concepts/filter_choices");
  return response.data;
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();
  const { setFilterOpened } = useFilters([
    "duration",
    "language",
    "resolution",
    "creative_launched_to",
  ]);

  const initialValues = {
    duration: state.duration || "",
    language: state.language || "",
    resolution: state.resolution || "",
    creative_launched_to: state.creative_launched_to || "",
  };

  const onChange = (values) => {
    setState({
      duration: values.duration || null,
      language: values.language || null,
      resolution: values.resolution || null,
      creative_launched_to: values.creative_launched_to || null,
      page: 1,
    });
    setFilterOpened(false);
  };
  return (
    <AssetsFiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
    />
  );
}

function AssetsFiltersForm({ options, initialValues, onChange }) {
  return (
    <Form initialValues={initialValues} syncSubmit onSubmit={onChange}>
      <Row className="mb-3 justify-content-md-center">
        <FilterCol label="Duration" md={1}>
          <Form.Select name="duration" options={options.duration} />
        </FilterCol>
        <FilterCol label="Language" md={1}>
          <Form.Select name="language" options={options.language} />
        </FilterCol>
        <FilterCol label="Resolution">
          <Form.Select name="resolution" options={options.resolution} />
        </FilterCol>
        <FilterCol label="Creative Launched To">
          <Form.Select
            name="creative_launched_to"
            options={options.creative_launched_to}
          />
        </FilterCol>
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-end" md={12} sm={10}>
          <Col xs="auto" className="me-2">
            <ResetButton />
          </Col>
          <Col xs="auto">
            <SubmitButton icon={false} title="Apply" />
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

export { filtersLoader, PageFiltersForm, AssetsFiltersForm };
