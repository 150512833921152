import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import * as Yup from "yup";

import { GdriveCredsGuard } from "~/blocks/gdrive/credentials";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import PageHeader from "~/components/page-header";
import { axios } from "~/utils";

function CreativeInfo({ newCreative }) {
  return (
    <Container>
      <Row>
        <Col xs={1}>
          {" "}
          <b>Concept #</b>
        </Col>
        <Col xs={1}>
          {" "}
          <b>App</b>
        </Col>
        <Col xs={5}>
          {" "}
          <b>Long Name</b>
        </Col>
        <Col xs={2}>
          {" "}
          <b>Resolution</b>
        </Col>
        <Col xs={2}>
          {" "}
          <b>Language</b>
        </Col>
      </Row>
      <Row className="my-3">
        <Col xs={1}>{newCreative.creative_number}</Col>
        <Col xs={1}>{newCreative.application_code}</Col>
        <Col xs={5}>{newCreative.long_name}</Col>
        <Col xs={2}>{newCreative.resolution}</Col>
        <Col xs={2}>{newCreative.language}</Col>
      </Row>
    </Container>
  );
}

/* eslint-disable no-template-curly-in-string */
const creativesValidationSchema = Yup.object().shape({
  creative_link: Yup.string()
    .test(
      "len",
      "can be empty or with string at least 2 characters and not more than 255",
      (val) => {
        if (val === undefined) {
          return true;
        }
        return val.length === 0 || (val.length >= 2 && val.length <= 255);
      }
    )
    .test("gdrive", "must be a valid google drive link", (val) => {
      if (val === undefined) {
        return true;
      }
      return val.startsWith("https://drive.google.com/file/d/");
    })
    .required("Required!"),
  concept_name: Yup.string()
    .test(
      "len",
      "can be empty or with string at least 2 characters and not more than 255",
      (val) => {
        if (val === undefined) {
          return true;
        }
        return val.length === 0 || (val.length >= 2 && val.length <= 255);
      }
    )
    .required("Required!"),
});
/* eslint-enable no-template-curly-in-string */

function CreativeCreator() {
  const [newCreative, setNewCreative] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [creationStatus, setCreationStatus] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const sendCreative = (values) => {
    setCreationStatus(null);
    setIsLoading(true);
    return axios({
      method: "POST",
      url: "creatives/",
      data: values,
    })
      .then((response) => {
        setNewCreative(response.data);
        setCreationStatus("success");
        setIsLoading(false);
      })
      .catch((errorResponse) => {
        setCreationStatus("error");
        setErrorMessage(errorResponse.response.data.long_name);
        setIsLoading(false);
      });
  };
  return (
    <>
      <Row className="my-4 d-flex align-items-center">
        <Form
          form={false}
          initialValues={{
            creative_link: "",
            concept_name: "",
          }}
          validationSchema={creativesValidationSchema}
          onSubmit={sendCreative}
        >
          <Row>
            <Col xs="auto">Creative Link:</Col>
            <Col xs={5}>
              <Form.Control
                name="creative_link"
                disabled={isLoading || creationStatus === "success"}
              />
              <Form.Control.ErrorFeedback name="creative_link" />
            </Col>
            <Col xs="auto">Concept Name:</Col>
            <Col xs={3}>
              <Form.Control
                name="concept_name"
                disabled={isLoading || creationStatus === "success"}
              />
              <Form.Control.ErrorFeedback name="concept_name" />
            </Col>
            <Col xs="auto">
              <SubmitButton
                className="px-4"
                icon={false}
                title="Save"
                disabled={isLoading || creationStatus === "success"}
              />
            </Col>
          </Row>
        </Form>
      </Row>
      {isLoading && (
        <Row>
          <Col xs={{ offset: 6, span: 1 }}>
            <FontAwesomeIcon
              className="fa fa-spin fa-2xl"
              icon={faSpinner}
              color="#0d6efd"
            />
          </Col>
        </Row>
      )}
      {creationStatus === "success" && (
        <>
          <Row>
            <CreativeInfo newCreative={newCreative} />
          </Row>
          <Row className="mb-3 mt-4">
            <Col xs={12} style={{ fontSize: "large", textAlign: "center" }}>
              Creative added to Cactus
              <FontAwesomeIcon
                className="fa fa-solid fa-2xl ms-3"
                icon={faCircleCheck}
                color="green"
              />
            </Col>
          </Row>
        </>
      )}
      {creationStatus === "error" && (
        <Row style={{ marginTop: "5rem" }} className="mb-3">
          <Col xs={12} style={{ fontSize: "large", textAlign: "center" }}>
            Creative not added. {errorMessage}
            <FontAwesomeIcon
              className="fa fa-solid fa-2xl ms-3"
              icon={faCircleXmark}
              color="red"
            />
          </Col>
        </Row>
      )}
      {creationStatus !== null && creationStatus !== "error" && (
        <Row>
          <Col xs={{ offset: 5, span: 2 }} style={{ textAlign: "center" }}>
            <Button href="/creatives/concepts/new">+ New Creative</Button>
          </Col>
        </Row>
      )}
    </>
  );
}

function CreationLayout() {
  return (
    <>
      <Row>
        <p className="mt-4">
          Please provide the creative’s Google Drive link and Concept Name:
        </p>
      </Row>
      <CreativeCreator />
    </>
  );
}

function CreativesCreatePage() {
  return (
    <>
      <PageHeader />
      <Container>
        <GdriveCredsGuard>
          <CreationLayout />
        </GdriveCredsGuard>
      </Container>
    </>
  );
}

export default CreativesCreatePage;
