import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

import { gdriveCredsContext } from "~/blocks/gdrive/credentials";
import Spinner from "~/components/spinner";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function GdriveCredsDropzoneGuard({ children }) {
  const { loading, refreshExist, triggerAuth } =
    React.useContext(gdriveCredsContext);

  if (loading) return <Spinner />;
  if (!refreshExist) {
    return (
      <div style={baseStyle}>
        <p>
          Please authenticate your TDS Google Account to allow uploading to
          Google Drive.
        </p>
        <p>
          <Button onClick={triggerAuth} type="button">
            Google Authenticate
          </Button>
        </p>
      </div>
    );
  }
  return children;
}

function AssetDropzone({ children }) {
  const [filesToUpload, setFilesToUpload] = useState([]);

  const removeFile = (key) =>
    setFilesToUpload(filesToUpload.filter((f) => f.key !== key));

  const clearFiles = () => setFilesToUpload([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFilesToUpload(
      acceptedFiles.map((file, fileIndex) => ({ key: fileIndex, file }))
    );
  }, []);
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    maxSize: 100 * 1000 * 1000,
    accept: {
      "video/mp4": [".mp4"],
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} name="assetFiles" />
        {isDragActive ? (
          <p style={{ paddingTop: "27.5px", paddingBottom: "20px" }}>
            <FontAwesomeIcon icon={faDownload} /> Drop the files here ...
          </p>
        ) : (
          <>
            <p>
              Drag-n-drop video assets here (each file under 100 MB) here, or{" "}
            </p>
            <p>
              <Button onClick={open} type="button">
                Select Files
              </Button>
            </p>
          </>
        )}
      </div>
      {children({ filesToUpload, removeFile, clearFiles })}
    </>
  );
}

export { GdriveCredsDropzoneGuard, AssetDropzone };
