import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useLoaderData, defer, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { GdriveCredsGuard } from "~/blocks/gdrive/credentials";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import PageHeader from "~/components/page-header";
import { axios } from "~/utils";

function applicationsToOptions(applications) {
  return applications.map((application) => ({
    label: application.code,
    value: application.id,
  }));
}

function ConceptCreationLayout({ applications, adTypes, inputTypeahead }) {
  return (
    <>
      <Row>
        <p className="mt-4">
          Please provide the following data to add and setup a new creative (*
          required fields):
        </p>
      </Row>
      <ConceptCreator
        applications={applications}
        adTypes={adTypes}
        inputTypeahead={inputTypeahead}
      />
    </>
  );
}

function CreativesConceptCreatePage() {
  const { applications, adTypes, inputTypeahead } = useLoaderData();

  return (
    <>
      <PageHeader />
      <Container>
        <GdriveCredsGuard>
          <ConceptCreationLayout
            applications={applications}
            adTypes={adTypes}
            inputTypeahead={inputTypeahead}
          />
        </GdriveCredsGuard>
      </Container>
    </>
  );
}

CreativesConceptCreatePage.loader = async function loader() {
  const [applicationsResponse] = await Promise.all([axios("applications")]);
  const [adTypesResponse] = await Promise.all([axios("ad-types")]);
  const [inputTypeaheadResponse] = await Promise.all([
    axios("creative-concepts/input_typeahead"),
  ]);

  return defer({
    applications: applicationsResponse.data,
    adTypes: adTypesResponse.data,
    inputTypeahead: inputTypeaheadResponse.data,
  });
};

function addEmptyOption(options) {
  return [{ label: "-", value: "" }, ...options];
}

/* eslint-disable no-template-curly-in-string */
const conceptValidationSchema = Yup.object().shape({
  application: Yup.number().required("Required"),
  intro: Yup.string(),
  concept_name: Yup.string().min(2).max(255).required("Required"),
  identifier: Yup.string(),
  iteration: Yup.boolean(),
  ad_type: Yup.number(),
});
/* eslint-enable no-template-curly-in-string */

function ConceptCreator({ applications, adTypes, inputTypeahead }) {
  const navigate = useNavigate();
  const [newConcept, setNewConcept] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [creationStatus, setCreationStatus] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const onSubmit = (values) => {
    setCreationStatus(null);
    setIsLoading(true);
    return axios({
      method: "POST",
      url: "creative-concepts/",
      data: values,
    })
      .then((response) => {
        setNewConcept(response.data);
        setCreationStatus("success");
        setIsLoading(false);
        navigate(`/creatives/concepts/${response.data.id}/assets`);
      })
      .catch((errorResponse) => {
        setCreationStatus("error");
        const message = errorResponse.response.data.non_field_errors
          ? errorResponse.response.data.non_field_errors[0]
          : errorResponse.response.data;
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Row className="my-4 d-flex align-items-center">
        <Form
          form={false}
          initialValues={{
            application: "",
            creative_number: "",
            intro: "",
            concept_name: "",
            identifier: "",
            iteration: false,
            ad_type: "",
          }}
          validationSchema={conceptValidationSchema}
          syncSubmit
          onSubmit={onSubmit}
        >
          <Row className="mb-3 pb-3">
            <Col xs={2}>Application:*</Col>
            <Col xs={5}>
              <Form.Select
                name="application"
                options={applicationsToOptions(applications)}
                disabled={isLoading || creationStatus === "success"}
              />
              <Form.Control.ErrorFeedback name="application" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={2}>
              Intro:{" "}
              <p style={{ color: "grey", fontSize: "11px" }}>(optional)</p>
            </Col>
            <Col xs={5}>
              <Form.Typeahead
                name="intro"
                disabled={isLoading || creationStatus === "success"}
                minLength={2}
                options={inputTypeahead.intro}
              />
              <Form.Control.ErrorFeedback name="intro" />
            </Col>
          </Row>
          <Row className="mb-3 pb-3">
            <Col xs={2}>Concept Name:*</Col>
            <Col xs={5}>
              <Form.Typeahead
                name="concept_name"
                disabled={isLoading || creationStatus === "success"}
                minLength={2}
                options={inputTypeahead.concept_name}
              />
              <Form.Control.ErrorFeedback name="concept_name" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={2}>
              Identifier:{" "}
              <p style={{ color: "grey", fontSize: "11px" }}>(optional)</p>
            </Col>
            <Col xs={5}>
              <Form.Typeahead
                name="identifier"
                disabled={isLoading || creationStatus === "success"}
                minLength={2}
                options={inputTypeahead.identifier}
              />
              <Form.Control.ErrorFeedback name="identifier" />
            </Col>
          </Row>
          <Row className="mb-3 pb-3">
            <Col xs={2}>Iteration:</Col>
            <Col xs={5}>
              <Form.Check
                name="iteration"
                disabled={isLoading || creationStatus === "success"}
              />
              <Form.Control.ErrorFeedback name="iteration" />
            </Col>
          </Row>
          <Row className="mb-3 pb-3">
            <Col xs={2}>
              Ad Type:{" "}
              <p style={{ color: "grey", fontSize: "11px" }}>(optional)</p>
            </Col>
            <Col xs={5}>
              <Form.Select
                name="ad_type"
                disabled={isLoading || creationStatus === "success"}
                options={addEmptyOption(adTypes)}
              />
              <Form.Control.ErrorFeedback name="ad_type" />
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              <SubmitButton
                className="px-4"
                icon={false}
                title="Save"
                disabled={isLoading || creationStatus === "success"}
              />
            </Col>
          </Row>
        </Form>
      </Row>
      {isLoading && (
        <Row>
          <Col xs={{ offset: 6, span: 1 }}>
            <FontAwesomeIcon
              className="fa fa-spin fa-2xl"
              icon={faSpinner}
              color="#0d6efd"
            />
          </Col>
        </Row>
      )}
      {creationStatus === "success" && (
        <>
          <Row>
            <a
              href={newConcept.creative_folder_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Drive Link
            </a>
          </Row>
          <Row className="mb-3 mt-4">
            <Col xs={12} style={{ fontSize: "large", textAlign: "center" }}>
              Creative added to Cactus
              <FontAwesomeIcon
                className="fa fa-solid fa-2xl ms-3"
                icon={faCircleCheck}
                color="green"
              />
            </Col>
          </Row>
        </>
      )}
      {creationStatus === "error" && (
        <Row style={{ marginTop: "5rem" }} className="mb-3">
          <Col xs={12} style={{ fontSize: "large", textAlign: "center" }}>
            Creative not added. {errorMessage}
            <FontAwesomeIcon
              className="fa fa-solid fa-2xl ms-3"
              icon={faCircleXmark}
              color="red"
            />
          </Col>
        </Row>
      )}
      {creationStatus !== null && creationStatus !== "error" && (
        <Row>
          <Col xs={{ offset: 5, span: 2 }} style={{ textAlign: "center" }}>
            <Button href="/creatives/concepts/new">+ New Creative</Button>
          </Col>
        </Row>
      )}
    </>
  );
}

export default CreativesConceptCreatePage;
