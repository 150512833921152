import moment from "moment/moment";
import React from "react";
import { Button, Table } from "react-bootstrap";
import { Link, useLoaderData, defer } from "react-router-dom";

import {
  filtersLoader,
  PageFiltersForm,
} from "~/blocks/end-cards/concepts/filters";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import { axios, extractSearch } from "~/utils";

function EndCardConceptsPage() {
  const { endCards, filtersPromise } = useLoaderData();

  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
        filterAlwaysOpened
      >
        <Button variant="primary" href="/end-cards/concepts/new">
          Add New End Card
        </Button>
      </PageHeader>
      <EndCardConceptsTable data={endCards} />
    </>
  );
}

EndCardConceptsPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const response = await axios(`end-card-concepts${search}`);
  const filtersPromise = filtersLoader();

  return defer({
    endCards: response.data,
    filtersPromise,
  });
};

function EndCardConceptsTable({ data }) {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th scope="col" style={{ width: "4em" }}>
              ID
            </th>
            <th scope="col" style={{ width: "15em" }}>
              Date
            </th>
            <th scope="col" style={{ width: "5em" }}>
              App
            </th>
            <th scope="col">End Card #</th>
            <th scope="col">Created By</th>
            <th scope="col">Theme</th>
            <th scope="col">End Card Folder Link</th>
            <th scope="col">View</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((concept) => (
            <tr key={concept.id}>
              <td>{concept.id}</td>
              <td>
                {moment.utc(concept.created_at).format("DD.MM.YYYY, HH:mm:ss")}
              </td>
              <td>{concept.application_code}</td>
              <td>{concept.creative_number}</td>
              <td>{concept.created_by_name}</td>
              <td>{concept.theme}</td>
              <td>
                <a
                  href={concept.creative_folder_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Drive Link
                </a>
              </td>
              <td>
                <Link to={`/end-cards/concepts/${concept.id}/assets`}>
                  <Button variant="outline-primary" className="mb-1 me-3">
                    View
                  </Button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

export default EndCardConceptsPage;
