import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./spinner.scss";

function Spinner() {
  return (
    <div className="spinner">
      <i />
      <i />
      <i />
      <i />
      <i />
      <i />
      <i />
    </div>
  );
}

function SmallSpinner() {
  return (
    <div className="small-spinner">
      <FontAwesomeIcon icon={faSpinner} />
    </div>
  );
}

export default Spinner;
export { SmallSpinner };
