const getVideoMetadata = (file) =>
  new Promise((resolve, reject) => {
    try {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = Math.round(video.duration);
        const width = Math.round(video.videoWidth);
        const height = Math.round(video.videoHeight);
        const resolution = `${width}x${height}`;
        resolve({ duration, width, height, resolution });
      };

      video.onerror = (e) => {
        reject(e);
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });

export { getVideoMetadata };
