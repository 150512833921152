import { useFormikContext } from "formik";
import React from "react";
import { Row, Col } from "react-bootstrap";

import { ResetButton, FilterCol } from "~/components/filters";
import Form from "~/components/form";
import DatePicker, { dateToString } from "~/components/form/date-picker";
import SubmitButton from "~/components/form/submit";
import { useFilters } from "~/components/page-header";
import { cachedAxios, useSearchParams } from "~/utils";

async function filtersLoader() {
  const response = await cachedAxios("/creatives/filter_choices");
  return response.data;
}

function DateRange({ name }) {
  const { values } = useFormikContext();
  const after = `${name}_after`;
  const before = `${name}_before`;
  const startDate = values[after];
  const endDate = values[before];
  return (
    <Row>
      <Col xs={6}>
        <DatePicker
          name={after}
          placeholderText="From:"
          autoComplete="off"
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
        />
      </Col>
      <Col xs={6}>
        <DatePicker
          name={before}
          placeholderText="To:"
          autoComplete="off"
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </Col>
    </Row>
  );
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();
  const { setFilterOpened } = useFilters([
    "application",
    "creative_number",
    "language",
    "resolution",
    "last_test_launch_time_after",
    "last_test_launch_time_before",
    "last_test_status",
    "creative_launched_to",
  ]);

  const initialValues = {
    creative_number: state.creative_number || "",
    last_test_launch_time_after: state.last_test_launch_time_after
      ? new Date(state.last_test_launch_time_after)
      : null,
    last_test_launch_time_before: state.last_test_launch_time_before
      ? new Date(state.last_test_launch_time_before)
      : null,
    application: state.application || "",
    language: state.language || "",
    resolution: state.resolution || "",
    last_test_status: state.last_test_status || "",
    creative_launched_to: state.creative_launched_to || "",
  };

  const onChange = (values) => {
    setState({
      creative_number: values.creative_number || null,
      last_test_launch_time_after:
        dateToString(values.last_test_launch_time_after) || null,
      last_test_launch_time_before:
        dateToString(values.last_test_launch_time_before) || null,
      application: values.application || null,
      language: values.language || null,
      resolution: values.resolution || null,
      last_test_status: values.last_test_status || null,
      creative_launched_to: values.creative_launched_to || null,
      page: 1,
    });
    setFilterOpened(false);
  };
  return (
    <AssetsFiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
    />
  );
}

function AssetsFiltersForm({ options, initialValues, onChange }) {
  return (
    <Form initialValues={initialValues} syncSubmit onSubmit={onChange}>
      <Row className="mb-3 justify-content-md-center">
        <FilterCol label="App" md={1}>
          <Form.Select name="application" options={options.application} />
        </FilterCol>
        <FilterCol label="Creative #" md={1}>
          <Form.Select
            name="creative_number"
            options={options.creative_number}
          />
        </FilterCol>
        <FilterCol label="Language" md={1}>
          <Form.Select name="language" options={options.language} />
        </FilterCol>
        <FilterCol label="Resolution">
          <Form.Select name="resolution" options={options.resolution} />
        </FilterCol>
        <FilterCol label="Last Test Launch Date" md={2}>
          <DateRange name="last_test_launch_time" />
        </FilterCol>
        <FilterCol label="Last Test Status">
          <Form.Select
            name="last_test_status"
            options={options.last_test_status}
          />
        </FilterCol>
        <FilterCol label="Creative Launched To">
          <Form.Select
            name="creative_launched_to"
            options={options.creative_launched_to}
          />
        </FilterCol>
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-end" md={12} sm={10}>
          <Col xs="auto" className="me-2">
            <ResetButton />
          </Col>
          <Col xs="auto">
            <SubmitButton icon={false} title="Apply" />
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

export { filtersLoader, PageFiltersForm, AssetsFiltersForm };
