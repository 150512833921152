import { useFormikContext } from "formik";
import React from "react";
import { Row, Col } from "react-bootstrap";

import { ResetButton, FilterCol } from "~/components/filters";
import Form from "~/components/form";
import DatePicker, { datetimeToString } from "~/components/form/date-picker";
import SubmitButton from "~/components/form/submit";
import { cachedAxios, useSearchParams, ensureList } from "~/utils";

async function filtersLoader() {
  const response = await cachedAxios("/task-results/filter-choices");
  return response.data;
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();

  const initialValues = {
    task_name: ensureList(state.task_name) || [],
    status: ensureList(state.status) || [],
    date_after: state.date_after || null,
    date_before: state.date_before || null,
  };

  const onChange = (values) => {
    setState({
      task_name: values.task_name || [],
      status: values.status || [],
      date_after: datetimeToString(values.date_after) || null,
      date_before: datetimeToString(values.date_before) || null,
      page: 1,
    });
  };
  return (
    <TaskFiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
    />
  );
}

function DateRange({ name, ...additionalProps }) {
  const { values } = useFormikContext();
  const after = `${name}_after`;
  const before = `${name}_before`;
  const startDate = values[after];
  const endDate = values[before];
  return (
    <Row>
      <Col xs={6}>
        <DatePicker
          name={after}
          placeholderText="From:"
          autoComplete="off"
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
          {...additionalProps}
        />
      </Col>
      <Col xs={6}>
        <DatePicker
          name={before}
          placeholderText="To:"
          autoComplete="off"
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          {...additionalProps}
        />
      </Col>
    </Row>
  );
}

function TaskFiltersForm({ options, initialValues, onChange }) {
  return (
    <Form initialValues={initialValues} syncSubmit onSubmit={onChange}>
      <Row className="mb-3 justify-content-md-center">
        <FilterCol label="Task name" md={3}>
          <Form.Select name="task_name" options={options.task_name} isMulti />
        </FilterCol>
        <FilterCol label="Status" md={2}>
          <Form.Select name="status" options={options.status} isMulti />
        </FilterCol>
        <FilterCol label="Date & Time" md={4}>
          <DateRange name="date" showTimeSelect dateFormat="MM/dd/yyyy HH:mm" />
        </FilterCol>
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-end" md={12} sm={10}>
          <Col xs="auto" className="me-2">
            <ResetButton />
          </Col>
          <Col xs="auto">
            <SubmitButton icon={false} title="Filter" />
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

export { filtersLoader, TaskFiltersForm, PageFiltersForm };
