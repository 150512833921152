import { useFormikContext } from "formik";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useAlert } from "~/base/alerts";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { axios, snakeToCamel } from "~/utils";

import { sortAssets, assetToOptions } from "./utils";

import "./index.scss";

const CreativePackType = Object.freeze({
  VIDEO_ENDCARD: "video-endcard",
  VIDEO_PLAYABLE: "video-playable",
  PLAYABLE: "playable",
});

const packTypeAssetsMap = {
  [CreativePackType.VIDEO_ENDCARD]: [
    "portraitVideoAsset",
    "landscapeVideoAsset",
    "endCard",
    "endCard2",
  ],
  [CreativePackType.VIDEO_PLAYABLE]: [
    "portraitVideoAsset",
    "landscapeVideoAsset",
    "playable",
  ],
  [CreativePackType.PLAYABLE]: ["playable"],
};

function formRequestData(values) {
  return {
    application: values.application,
    platform: values.platform,
    portrait_video_asset: values.portraitVideoAsset,
    landscape_video_asset: values.landscapeVideoAsset,
    end_card: values.endCard,
    end_card_2: values.endCard2,
    playable: values.playable,
  };
}

function UnityLaunchNetworkForm({ options }) {
  const navigate = useNavigate();
  const addAlert = useAlert();

  const onSubmit = (values, { setFieldError }) => {
    if (values.creativePackType !== CreativePackType.PLAYABLE) {
      if (!values.landscapeVideoAsset && !values.portraitVideoAsset) {
        setFieldError(
          "landscapeVideoAsset",
          "Please specify either Landscape or Portrait video asset."
        );
        setFieldError(
          "portraitVideoAsset",
          "Please specify either Landscape or Portrait video asset."
        );
        return;
      }
    }
    axios({
      method: "POST",
      url: "unity-launches/",
      data: formRequestData(values),
    })
      .then(() => {
        navigate("/network-launches");
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          const backendErrors = error.response.data;
          if (backendErrors.non_field_errors) {
            addAlert(backendErrors.non_field_errors[0], "error");
          } else {
            Object.keys(backendErrors).forEach((field) => {
              setFieldError(snakeToCamel(field), backendErrors[field]);
            });
          }
        } else {
          addAlert(
            "Unexpected error happened on form submission. Please try again or reach out to the MAD Products Team",
            "error"
          );
        }
      });
  };

  const validationSchema = Yup.object().shape({
    application: Yup.string().required("Please specify Application"),
    creativePackType: Yup.string().required(
      "Please specify Creative Pack Type"
    ),
    platform: Yup.string().required("Please specify Platform"),
    landscapeVideoAsset: Yup.number().nullable().notRequired(),
    portraitVideoAsset: Yup.number().nullable().notRequired(),
    endCard: Yup.number().when(["creativePackType"], {
      is: CreativePackType.VIDEO_ENDCARD,
      then: Yup.number().required("Please specify End card"),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    endCard2: Yup.number().nullable().notRequired(),
    playable: Yup.number().when(["creativePackType"], {
      is: CreativePackType.VIDEO_ENDCARD,
      then: Yup.number().nullable().notRequired(),
      otherwise: Yup.number().required("Please specify Playable"),
    }),
  });
  const platforms = [
    { label: "ios", value: "ios" },
    { label: "android", value: "android" },
  ];
  const creativePackTypes = [
    { label: "Video & End Card", value: CreativePackType.VIDEO_ENDCARD },
    { label: "Video & Playable", value: CreativePackType.VIDEO_PLAYABLE },
    { label: "Playable", value: CreativePackType.PLAYABLE },
  ];

  return (
    <Form
      form={false}
      initialValues={{
        application: null,
        portraitVideoAsset: null,
        landscapeVideoAsset: null,
        creativePackType: null,
        endCard: null,
        endCard2: null,
        playable: null,
        platform: null,
      }}
      validationSchema={validationSchema}
      syncSubmit
      onSubmit={onSubmit}
    >
      <Row className="mb-3 pb-3">
        <Col xs={2}>Application:*</Col>
        <Col xs={5}>
          <Form.Select name="application" options={options.applications} />
          <Form.Control.ErrorFeedback name="application" />
        </Col>
      </Row>
      <Row className="mb-3 pb-3">
        <Col xs={2}>Platform:*</Col>
        <Col xs={5}>
          <Form.Select name="platform" options={platforms} />
          <Form.Control.ErrorFeedback name="platform" />
        </Col>
      </Row>
      <Row className="mb-3 pb-3">
        <Col xs={2}>Creative pack type:*</Col>
        <Col xs={5}>
          <Form.Select name="creativePackType" options={creativePackTypes} />
          <Form.Control.ErrorFeedback name="creativePackType" />
        </Col>
      </Row>
      <AssetInputs />
      <Row>
        <Col xs="auto">
          <SubmitButton className="px-4" icon={false} title="Launch" />
        </Col>
      </Row>
    </Form>
  );
}

function AssetInputs() {
  const {
    values: {
      application,
      portraitVideoAsset,
      endCard,
      endCard2,
      landscapeVideoAsset,
      creativePackType,
    },
  } = useFormikContext();
  const addAlert = useAlert();
  const [portraitVideos, setPortraitVideos] = useState([]);
  const [landscapeVideos, setLandscapeVideos] = useState([]);
  const [endCardOptions, setEndCardOptions] = useState([]);
  const [playableOptions, setPlayableOptions] = useState([]);

  useEffect(() => {
    if (application !== null) {
      axios({
        method: "GET",
        url: `unity-launches/asset-options/?application=${application}`,
      })
        .then((response) => {
          setPortraitVideos(response.data.portrait_videos);
          setLandscapeVideos(response.data.landscape_videos);
          setEndCardOptions(response.data.end_cards);
          setPlayableOptions(response.data.playables);
        })
        .catch(() => {
          addAlert(
            "Error on loading creative concepts and end cards. Please try again or reach out to the MAD Products Team",
            "error"
          );
        });
    }
  }, [application, addAlert]);
  useEffect(() => {
    if (portraitVideoAsset !== null) {
      const selectedPortraitVideo = portraitVideos.find(
        (video) => video.id === portraitVideoAsset
      );
      setLandscapeVideos((videos) =>
        sortAssets(videos, selectedPortraitVideo.creative)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portraitVideoAsset]);
  useEffect(() => {
    if (landscapeVideoAsset !== null) {
      const selectedLandscapeVideoAsset = landscapeVideos.find(
        (video) => video.id === landscapeVideoAsset
      );
      setPortraitVideos((videos) =>
        sortAssets(videos, selectedLandscapeVideoAsset.creative)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landscapeVideoAsset]);
  useEffect(() => {
    if (endCard !== null) {
      const selectedEndCard = endCardOptions.find((e) => e.id === endCard);
      setEndCardOptions((endCards) =>
        sortAssets(endCards, selectedEndCard.creative)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endCard]);
  useEffect(() => {
    if (endCard2 !== null) {
      const selectedEndCard = endCardOptions.find((e) => e.id === endCard2);
      setEndCardOptions((endCards) =>
        sortAssets(endCards, selectedEndCard.creative)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endCard2]);

  if (creativePackType === null || application === null) {
    return "";
  }
  const assets = packTypeAssetsMap[creativePackType];

  function getEndCardType(endCardId) {
    const card = endCardOptions.find((e) => e.id === endCardId);
    return card ? card.type : null;
  }

  const squareEndCardSelected = getEndCardType(endCard) === "square";
  return (
    <div>
      <AssetInput assets={assets} name="portraitVideoAsset">
        <Col xs={2}>Portrait Video:*</Col>
        <Col xs={5}>
          <Form.Select
            name="portraitVideoAsset"
            options={assetToOptions(portraitVideos)}
            isClearable
          />
          <Form.Control.ErrorFeedback name="portraitVideoAsset" />
        </Col>
      </AssetInput>
      <AssetInput assets={assets} name="landscapeVideoAsset">
        <Col xs={2}>Landscape Video:*</Col>
        <Col xs={5}>
          <Form.Select
            name="landscapeVideoAsset"
            options={assetToOptions(landscapeVideos)}
            isClearable
          />
          <Form.Control.ErrorFeedback name="landscapeVideoAsset" />
        </Col>
      </AssetInput>
      <AssetInput assets={assets} name="endCard">
        <Col xs={2}>End Card #:*</Col>
        <Col xs={5}>
          <Form.Select
            name="endCard"
            options={assetToOptions(endCardOptions)}
          />
          <Form.Control.ErrorFeedback name="endCard" />
        </Col>
      </AssetInput>
      <AssetInput
        disabled={squareEndCardSelected}
        assets={assets}
        name="endCard2"
      >
        <Col xs={2}>End Card 2 #:</Col>
        <Col xs={5}>
          <Form.Select
            name="endCard2"
            options={assetToOptions(endCardOptions)}
            isClearable
          />
          <Form.Control.ErrorFeedback name="endCard2" />
        </Col>
      </AssetInput>
      <AssetInput assets={assets} name="playable">
        <Col xs={2}>Playable #:*</Col>
        <Col xs={5}>
          <Form.Select name="playable" options={playableOptions} isClearable />
          <Form.Control.ErrorFeedback name="playable" />
        </Col>
      </AssetInput>
    </div>
  );
}

function AssetInput({ assets, name, disabled, children }) {
  if (!assets.includes(name)) {
    return "";
  }
  const classes = disabled ? "mb-3 pb-3 disabled" : "mb-3 pb-3";
  return <Row className={classes}>{children}</Row>;
}

export default UnityLaunchNetworkForm;
