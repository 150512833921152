import moment from "moment";
import React from "react";
import { Button, Table } from "react-bootstrap";
import { Link, useLoaderData, defer } from "react-router-dom";

import {
  filtersLoader,
  PageFiltersForm,
} from "~/blocks/creatives/list/filters";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import { axios, extractSearch } from "~/utils";

function CreativesPage() {
  const { creatives, filtersPromise } = useLoaderData();

  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
        filterAlwaysOpened
      >
        <Button variant="primary" href="/creatives/concepts/new">
          Add New Asset
        </Button>
      </PageHeader>
      <CreativesTable data={creatives} />
    </>
  );
}

CreativesPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const response = await axios(`creatives${search}`);
  const filtersPromise = filtersLoader();

  return defer({
    creatives: response.data,
    filtersPromise,
  });
};

function Status({ status }) {
  let text = status;
  if (status === null) {
    text = "N/A";
  }
  if (status === "passed" || status === " %") {
    text = "Passed";
  }
  if (status === "failed") {
    text = "Failed";
  }
  if (status === "in_progress") {
    text = "In progress";
  }
  if (status === "test_in_progress") {
    text = "Test in progress";
  }
  return <span>{text}</span>;
}

function CreativesTable({ data }) {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th scope="col">Creative ID</th>
            <th scope="col">Concept Name</th>
            <th scope="col">Long Name</th>
            <th scope="col">Creative Link</th>
            <th scope="col">Test Outcome</th>
            <th scope="col">CVR benchmark</th>
            <th scope="col">Latest Test Launch Date</th>
            <th scope="col">Creative Launched To</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((creative) => (
            <tr key={creative.id}>
              <td>{creative.creative_human_id}</td>
              <td>{creative.concept_name}</td>
              <td>{creative.long_name}</td>
              <td>
                <a
                  href={creative.creative_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </td>
              <td>
                <Status status={creative.test_outcome} />
              </td>
              <td>{creative.cvr_benchmark ? creative.cvr_benchmark : "N/A"}</td>
              <td>
                <p>
                  {creative.last_test_launch_time
                    ? moment
                        .utc(creative.last_test_launch_time)
                        .format("DD.MM.YYYY")
                    : "N/A"}
                </p>
              </td>
              <td>
                {creative.creative_launched_to !== null
                  ? creative.creative_launched_to
                  : "-"}
              </td>
              <td>
                <Button variant="outline-primary" className="mb-1 me-3 d-none">
                  Test
                </Button>

                <Link to={`/creatives/${creative.id}/launch`}>
                  <Button variant="outline-primary" className="mb-1 me-3">
                    Launch
                  </Button>
                </Link>
                <Button variant="outline-primary" className="mb-1 me-3 d-none">
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

export default CreativesPage;
