import React from "react";
import { Row, Col } from "react-bootstrap";

import { ResetButton, FilterCol } from "~/components/filters";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { useFilters } from "~/components/page-header";
import { cachedAxios, useSearchParams } from "~/utils";

async function filtersLoader() {
  const response = await cachedAxios("/creative-concepts/filter_choices");
  return response.data;
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();
  const { setFilterOpened } = useFilters([
    "application",
    "creative_number",
    "concept_name",
    "agency",
  ]);

  const initialValues = {
    application: state.application || "",
    creative_number: state.creative_number || "",
    concept_name: state.concept_name || "",
    intro: state.intro || "",
    identifier: state.identifier || "",
    agency: state.agency || "",
  };

  const onChange = (values) => {
    setState({
      application: values.application || null,
      creative_number: values.creative_number || null,
      intro: values.intro || null,
      concept_name: values.concept_name || null,
      identifier: values.identifier || null,
      agency: values.agency || null,
      page: 1,
    });
    setFilterOpened(false);
  };
  return (
    <ConceptsFiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
    />
  );
}

function ConceptsFiltersForm({ options, initialValues, onChange }) {
  return (
    <Form initialValues={initialValues} syncSubmit onSubmit={onChange}>
      <Row className="mb-3 justify-content-md-center">
        <FilterCol label="App" md={2}>
          <Form.Select name="application" options={options.application} />
        </FilterCol>
        <FilterCol label="Creative #" md={2}>
          <Form.Select
            name="creative_number"
            options={options.creative_number}
          />
        </FilterCol>
        <FilterCol label="Intro" md={2}>
          <Form.Select name="intro" options={options.intro} />
        </FilterCol>
        <FilterCol label="Identifier" md={2}>
          <Form.Select name="identifier" options={options.identifier} />
        </FilterCol>
        <FilterCol label="Agency" md={2}>
          <Form.Select name="agency" options={options.agency} />
        </FilterCol>
        <FilterCol label="Concept Name" md={2}>
          <Form.Control name="concept_name" />
        </FilterCol>
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-end" md={12} sm={10}>
          <Col xs="auto" className="me-2">
            <ResetButton />
          </Col>
          <Col xs="auto">
            <SubmitButton icon={false} title="Filter" />
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

export { filtersLoader, PageFiltersForm, ConceptsFiltersForm };
