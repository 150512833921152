import {
  faPhotoFilm,
  faScroll,
  faRotate,
  faFileText,
  faRocket,
  faListCheck,
  faCirclePlay,
} from "@fortawesome/free-solid-svg-icons";
import { createBrowserRouter } from "react-router-dom";

import { getСreativeBreadCrumbName } from "~/base/breadcrumb";
import Layout from "~/base/layout";

import {
  HomePage,
  NotFoundPage,
  ErrorPage,
  LogoutPage,
  AuthErrorPage,
  AuthCallbackPage,
  CreativesHomePage,
  CreativesConceptsRoot,
  CreativesConceptsPage,
  CreativeAssetsPage,
  ActionLogsPage,
  CreativesConceptCreatePage,
  JobsPage,
  EndCardsHomePage,
  EndCardConceptsRoot,
  EndCardConceptsPage,
  EndCardConceptCreatePage,
  NetworkLaunchesPage,
  LaunchNetworkPage,
  EndCardAssetsPage,
  TasksPage,
  PlayablesHomePage,
  PlayableConceptsRoot,
  PlayableConceptsPage,
  PlayableConceptCreatePage,
  PlayableAssetsPage,
  LinkAgencyCreativePage,
} from "./pages";

// handle.navbar.color should be in #ffffff format
const config = [
  {
    path: "logout",
    element: <LogoutPage />,
  },
  {
    path: "auth/sso/callback",
    loader: AuthCallbackPage.loader,
    element: <AuthCallbackPage />,
  },
  {
    path: "auth/error",
    element: <AuthErrorPage />,
  },
  {
    path: "/*",
    loader: Layout.loader,
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <HomePage />,
            handle: {
              title: "CACTUS",
            },
          },
          {
            path: "creatives",
            handle: {
              title: "Creatives",
              requiredPermissions: "cactus_access:creatives:edit",
            },
            children: [
              {
                index: true,
                element: <CreativesHomePage />,
              },
              {
                path: "concepts",
                element: <CreativesConceptsRoot />,
                children: [
                  {
                    index: true,
                    loader: CreativesConceptsPage.loader,
                    element: <CreativesConceptsPage />,
                    handle: {
                      crumb: "Creatives Library",
                      title: "Creatives Library",
                      navbar: {
                        title: "Creatives Library",
                        icon: faPhotoFilm,
                      },
                    },
                  },
                  {
                    path: "new",
                    loader: CreativesConceptCreatePage.loader,
                    element: <CreativesConceptCreatePage />,
                    handle: {
                      crumb: "New Creative Concept",
                      title: "New Creative Concept",
                    },
                  },
                  {
                    path: "agency-new",
                    element: <LinkAgencyCreativePage />,
                    handle: {
                      crumb: "Link Agency Creative Concept",
                      title: "Link Agency Creative Concept",
                    },
                  },
                  {
                    path: ":conceptID",
                    handle: {
                      crumb: (crumbData) =>
                        getСreativeBreadCrumbName(crumbData, [
                          "creative_number",
                          "intro",
                          "concept_name",
                          "identifier",
                        ]) || "Creative Concept",
                    },
                    children: [
                      {
                        path: "assets",
                        loader: CreativeAssetsPage.loader,
                        element: <CreativeAssetsPage />,
                        handle: {
                          getCrumbData: (data) => data.creative,
                          title: (data) =>
                            `Creative Assets for: ${data.creative.concept_name}`,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "end-cards",
            handle: {
              title: "End Cards",
              requiredPermissions: "cactus_access:end_cards:edit",
            },
            children: [
              {
                index: true,
                element: <EndCardsHomePage />,
              },
              {
                path: "concepts",
                element: <EndCardConceptsRoot />,
                children: [
                  {
                    index: true,
                    loader: EndCardConceptsPage.loader,
                    element: <EndCardConceptsPage />,
                    handle: {
                      crumb: "End Cards Library",
                      title: "End Cards Library",
                      navbar: {
                        title: "End Cards Library",
                        icon: faFileText,
                      },
                    },
                  },
                  {
                    path: "new",
                    loader: EndCardConceptCreatePage.loader,
                    element: <EndCardConceptCreatePage />,
                    handle: {
                      crumb: "New End Card Concept",
                      title: "New End Card Concept",
                    },
                  },
                  {
                    path: ":conceptID",
                    handle: {
                      crumb: (concept) =>
                        getСreativeBreadCrumbName(concept, [
                          "creative_number",
                          "theme",
                        ]) || "End Card Concept",
                    },
                    children: [
                      {
                        path: "assets",
                        loader: EndCardAssetsPage.loader,
                        element: <EndCardAssetsPage />,
                        handle: {
                          getCrumbData: (data) => data.endCard,
                          title: (data) =>
                            `End Cards for: ${data.endCard.theme}`,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "playables",
            handle: {
              title: "Playables",
              requiredPermissions: "cactus_access:playables:edit",
            },
            children: [
              {
                index: true,
                element: <PlayablesHomePage />,
              },
              {
                path: "concepts",
                element: <PlayableConceptsRoot />,
                children: [
                  {
                    index: true,
                    loader: PlayableConceptsPage.loader,
                    element: <PlayableConceptsPage />,
                    handle: {
                      crumb: "Playables Library",
                      title: "Playables Library",
                      navbar: {
                        title: "Playables Library",
                        icon: faCirclePlay,
                      },
                    },
                  },
                  {
                    path: "new",
                    loader: PlayableConceptCreatePage.loader,
                    element: <PlayableConceptCreatePage />,
                    handle: {
                      crumb: "New Playables Concept",
                      title: "New Playables Concept",
                    },
                  },
                  {
                    path: ":conceptID",
                    handle: {
                      crumb: (concept) =>
                        getСreativeBreadCrumbName(concept, [
                          "creative_number",
                          "theme",
                        ]) || "Playables Concept",
                    },
                    children: [
                      {
                        path: "assets",
                        loader: PlayableAssetsPage.loader,
                        element: <PlayableAssetsPage />,
                        handle: {
                          getCrumbData: (data) => data.playable,
                          title: (data) =>
                            `Playables for: ${data.playable.theme}`,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "activity-logs",
            handle: {
              crumb: "Activity Log",
              title: "Activity Log",
              requiredPermissions: "cactus_access:activity_logs:edit",
              navbar: {
                title: "Activity Log",
                icon: faScroll,
              },
            },
            children: [
              {
                index: true,
                loader: ActionLogsPage.loader,
                element: <ActionLogsPage />,
              },
            ],
          },
          {
            path: "network-launches",
            handle: {
              crumb: "Network Launches",
              title: "Network launches",
              requiredPermissions: "cactus_access:network_launches:edit",
              navbar: {
                title: "Network Launches",
                icon: faRocket,
              },
            },
            children: [
              {
                index: true,
                loader: NetworkLaunchesPage.loader,
                element: <NetworkLaunchesPage />,
              },
              {
                path: "new",
                loader: LaunchNetworkPage.loader,
                element: <LaunchNetworkPage />,
                handle: {
                  crumb: "Launch creative assets to networks",
                  title: "Launch creative assets",
                },
              },
            ],
          },
          {
            path: "jobs",
            handle: {
              crumb: "Job center",
              title: "Job center",
              requiredPermissions: "cactus_access:jobs_center:edit",
              navbar: {
                title: "Job center",
                icon: faRotate,
              },
            },
            children: [
              {
                index: true,
                loader: JobsPage.loader,
                element: <JobsPage />,
              },
            ],
          },
          {
            path: "tasks",
            handle: {
              crumb: "Task center",
              title: "Task center",
              requiredPermissions: "admin:admin:edit",
              navbar: {
                title: "Task center",
                icon: faListCheck,
              },
            },
            children: [
              {
                index: true,
                loader: TasksPage.loader,
                element: <TasksPage />,
              },
            ],
          },
          {
            path: "*",
            element: <NotFoundPage />,
            handle: {
              crumb: "Page not found",
              title: "CACTUS: page not found",
            },
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(config);

export default router;
