import MultiSelect from "@kenshooui/react-multi-select";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useAlert } from "~/base/alerts";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { axios, snakeToCamel } from "~/utils";

import { sortAssets, assetToOptions } from "./utils";

import "./index.scss";

function formRequestData(values, campaigns) {
  return campaigns.map((item) => ({
    application: values.application,
    language: values.language,
    portrait_video_asset: values.portraitVideoAsset,
    landscape_video_asset: values.landscapeVideoAsset,
    end_card: values.endCard,
    playable: values.playable,
    campaign_id: item.id,
    campaign_name: item.label,
  }));
}

function ApplovinLaunchNetworkForm({ options }) {
  const navigate = useNavigate();
  const [campaignOptions, setCampaignsOptions] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const addAlert = useAlert();

  const onSubmit = (values, { setFieldError }) => {
    if (selectedCampaigns.length === 0) {
      addAlert("Please select at least 1 campaign", "error");
      return;
    }
    axios({
      method: "POST",
      url: "applovin-launches/",
      data: formRequestData(values, selectedCampaigns),
    })
      .then(() => {
        navigate("/network-launches");
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          const backendErrors = error.response.data;
          if (backendErrors[0].non_field_errors) {
            addAlert(backendErrors[0].non_field_errors[0], "error");
          } else {
            Object.keys(backendErrors[0]).forEach((field) => {
              setFieldError(snakeToCamel(field), backendErrors[0][field]);
            });
          }
        } else {
          addAlert(
            "Unexpected error happened on form submission. Please try again or reach out to the MAD Products Team",
            "error"
          );
        }
      });
  };

  const validationSchema = Yup.object().shape({
    application: Yup.string().required("Application is required"),
    language: Yup.string().required("Language is required"),
    portraitVideoAsset: Yup.number().when("endCard", {
      is: (val) => val === undefined || val === null,
      then: Yup.number().nullable().notRequired(),
      otherwise: Yup.number()
        .required()
        .integer("Portrait video asset must be an integer"),
    }),
    landscapeVideoAsset: Yup.number().when("endCard", {
      is: (val) => val === undefined || val === null,
      then: Yup.number().nullable().notRequired(),
      otherwise: Yup.number()
        .required()
        .integer("Landscape video asset must be an integer"),
    }),
    endCard: Yup.number().nullable().integer("End card must be an integer"),
    playable: Yup.number().nullable().integer("Playable must be an integer"),
  });

  return (
    <Form
      form={false}
      initialValues={{
        application: null,
        language: null,
        portraitVideoAsset: null,
        landscapeVideoAsset: null,
        endCard: null,
        playable: null,
      }}
      validationSchema={validationSchema}
      syncSubmit
      onSubmit={onSubmit}
    >
      <Row className="mb-3 pb-3">
        <Col xs={2}>Application:*</Col>
        <Col xs={5}>
          <Form.Select name="application" options={options.applications} />
          <Form.Control.ErrorFeedback name="application" />
        </Col>
      </Row>
      <Row className="mb-3 pb-3">
        <Col xs={2}>Language:*</Col>
        <Col xs={5}>
          <Form.Select name="language" options={options.languages} />
          <Form.Control.ErrorFeedback name="language" />
        </Col>
      </Row>
      <AssetCampaignInputs
        campaignOptions={campaignOptions}
        setCampaignsOptions={setCampaignsOptions}
        selectedCampaigns={selectedCampaigns}
        setSelectedCampaigns={setSelectedCampaigns}
      />
      <Row>
        <Col xs="auto">
          <SubmitButton className="px-4" icon={false} title="Launch" />
        </Col>
      </Row>
    </Form>
  );
}

function campaignsToOptions(campaigns) {
  return campaigns.map((campaign) => ({
    id: campaign.id,
    label: campaign.name,
  }));
}

function AssetCampaignInputs({
  campaignOptions,
  setCampaignsOptions,
  selectedCampaigns,
  setSelectedCampaigns,
}) {
  const {
    values: {
      application,
      endCard,
      playable,
      portraitVideoAsset,
      landscapeVideoAsset,
    },
  } = useFormikContext();
  const [portraitVideos, setPortraitVideos] = useState([]);
  const [landscapeVideos, setLandscapeVideos] = useState([]);
  const [endCardOptions, setEndCardOptions] = useState([]);
  const [playableOptions, setPlayableOptions] = useState([]);

  const applicationSelected = application !== null;
  const addAlert = useAlert();

  useEffect(() => {
    if (applicationSelected) {
      axios({
        method: "GET",
        url: `applovin-launches/asset-options/?application=${application}`,
      })
        .then((response) => {
          setPortraitVideos(response.data.portrait_videos);
          setLandscapeVideos(response.data.landscape_videos);
          setEndCardOptions(response.data.end_cards);
          setPlayableOptions(response.data.playables);
        })
        .catch(() => {
          addAlert(
            "Error on loading asset options. Please try again or reach out to the MAD Products Team",
            "error"
          );
        });
      axios({
        method: "GET",
        url: "network-campaings/?network=Applovin",
      })
        .then((response) => {
          const options = campaignsToOptions(response.data);
          setCampaignsOptions(options);
        })
        .catch(() => {
          addAlert(
            "Error on loading campaigns. Please try again or reach out to the MAD Products Team",
            "error"
          );
        });
    }
  }, [application, applicationSelected, setCampaignsOptions, addAlert]);
  useEffect(() => {
    if (portraitVideoAsset !== null) {
      const selectedPortraitVideo = portraitVideos.find(
        (video) => video.id === portraitVideoAsset
      );
      setLandscapeVideos((videos) =>
        sortAssets(videos, selectedPortraitVideo.creative)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portraitVideoAsset]);
  useEffect(() => {
    if (landscapeVideoAsset !== null) {
      const selectedLandscapeVideoAsset = landscapeVideos.find(
        (video) => video.id === landscapeVideoAsset
      );
      setPortraitVideos((videos) =>
        sortAssets(videos, selectedLandscapeVideoAsset.creative)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landscapeVideoAsset]);
  const hasValue = (value) =>
    value !== null && value !== undefined && value !== null;

  return (
    <div className={classNames({ disabled: !applicationSelected })}>
      <Row className="mb-3 pb-3">
        <Col xs={2}>Portrait Video:*</Col>
        <Col xs={5}>
          <Form.Select
            name="portraitVideoAsset"
            options={assetToOptions(portraitVideos)}
            isClearable
          />
          <Form.Control.ErrorFeedback name="portraitVideoAsset" />
        </Col>
      </Row>
      <Row className="mb-3 pb-3">
        <Col xs={2}>Landscape Video*:</Col>
        <Col xs={5}>
          <Form.Select
            name="landscapeVideoAsset"
            options={assetToOptions(landscapeVideos)}
            isClearable
          />
          <Form.Control.ErrorFeedback name="landscapeVideoAsset" />
        </Col>
      </Row>
      <Row
        className={classNames("mb-3 pb-3", { disabled: hasValue(playable) })}
      >
        <Col xs={2}>End Card #:</Col>
        <Col xs={5}>
          <Form.Select name="endCard" options={endCardOptions} isClearable />
          <Form.Control.ErrorFeedback name="endCard" />
        </Col>
      </Row>
      <Row className={classNames("mb-3 pb-3", { disabled: hasValue(endCard) })}>
        <Col xs={2}>Playable #:</Col>
        <Col xs={5}>
          <Form.Select name="playable" options={playableOptions} isClearable />
          <Form.Control.ErrorFeedback name="playable" />
        </Col>
      </Row>
      <Row className="mb-3 pb-3">
        <Col xs={2}>Campaigns:</Col>
        <Col xs={5}>
          <MultiSelect
            items={campaignOptions}
            itemHeight={40}
            selectAllHeight={40}
            selectedItems={selectedCampaigns}
            onChange={setSelectedCampaigns}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ApplovinLaunchNetworkForm;
