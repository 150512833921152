/* eslint-disable camelcase */
import moment from "moment";
import React from "react";
import { Table, Button } from "react-bootstrap";
import { useLoaderData, defer } from "react-router-dom";

import { useAlert } from "~/base/alerts";
import { filtersLoader, PageFiltersForm } from "~/blocks/task-filters";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import { axios, extractSearch } from "~/utils";
import "./tasks.scss";

function TasksPage() {
  const { tasks, filtersPromise } = useLoaderData();
  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
        filterAlwaysOpened
      />
      <TasksTable data={tasks} />
    </>
  );
}

TasksPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const response = await axios(`task-results${search}`);
  const filtersPromise = filtersLoader();

  return defer({
    tasks: response.data,
    filtersPromise,
  });
};

function renderKeyValuePairs(data) {
  return Object.entries(data).map(([key, value]) => (
    <li key={key}>
      {key}: {typeof value === "object" ? JSON.stringify(value) : value}
    </li>
  ));
}

function renderListArguments(args) {
  return args.map((arg, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <li key={index}>{typeof arg === "object" ? JSON.stringify(arg) : arg}</li>
  ));
}

function TaskMeta({ meta }) {
  const renderRelatedModels = (relatedModels) =>
    relatedModels.map((obj) => (
      <li key={obj.name}>
        <a href={obj.link}>{obj.name}</a>
      </li>
    ));
  const { related_models, ...otherKeys } = meta;

  return (
    <ul>
      {related_models && (
        <div>
          <li>Related Models:</li>
          <ul>{renderRelatedModels(related_models)}</ul>
        </div>
      )}
      {renderKeyValuePairs(otherKeys)}
    </ul>
  );
}

function TaskKwargs({ kwargs }) {
  return renderKeyValuePairs(kwargs);
}

function TaskArgs({ args }) {
  return renderListArguments(args);
}

function TasksTable({ data }) {
  return (
    <>
      <Table striped bordered hover className="task-center-table">
        <thead>
          <tr>
            <th scope="col" className="short-cell">
              ID
            </th>
            <th scope="col">Created</th>
            <th scope="col">Done</th>
            <th scope="col">Name</th>
            <th scope="col" className="short-cell">
              Status
            </th>
            <th scope="col" className="short-cell">
              Args
            </th>
            <th scope="col" className="long-cell">
              Kwargs
            </th>
            <th scope="col" className="long-cell">
              Meta
            </th>
            <th scope="col" className="short-cell">
              Admin link
            </th>
            <th scope="col" className="short-cell">
              Retry
            </th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((task) => (
            <tr key={task.id}>
              <td className="short-cell">{task.id}</td>
              <td>
                {moment.utc(task.date_created).format("MMMM D, YYYY HH:mm:ss")}
              </td>
              <td>
                {moment.utc(task.date_done).format("MMMM D, YYYY HH:mm:ss")}
              </td>
              <td>{task.task_name}</td>
              <td className="short-cell">{task.status}</td>
              <td className="short-cell">
                <TaskArgs args={task.task_args} />
              </td>
              <td className="long-cell">
                <TaskKwargs kwargs={task.task_kwargs} />
              </td>
              <td className="long-cell">
                <TaskMeta meta={task.meta} />
              </td>
              <td className="short-cell">
                <a href={task.admin_link}>Link</a>
              </td>
              <td className="short-cell">
                <div className="d-flex flex-row">
                  <RetryButton task={task} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

function RetryButton({ task }) {
  const addAlert = useAlert();
  const retryTask = () =>
    axios({
      method: "POST",
      url: `task-results/${task.id}/retry/`,
    })
      .then(() => {
        addAlert("Triggered failed task to retry", "info");
      })
      .catch(() => {
        addAlert("Error on re-trying task", "error");
      });
  return (
    <Button variant="info" size="sm" onClick={retryTask}>
      Retry
    </Button>
  );
}

export default TasksPage;
