import React from "react";
import { Button } from "react-bootstrap";

import { useAlert } from "~/base/alerts";
import { axios } from "~/utils";

function DeleteFilesButton({
  files,
  endpoint,
  refreshPage,
  disableAction = false,
}) {
  const addAlert = useAlert();
  const handleDelete = (files_) => {
    const deletePromises = files_.map((id) =>
      axios({
        method: "DELETE",
        url: `${endpoint}/${id}`,
      })
        .then(() => id)
        .catch((error) => (error.response.status === 429 ? id : null))
    );
    Promise.all(deletePromises).then((results) => {
      const deleted = results.filter((id) => id !== null);
      const notDeleted = files_.filter((id) => !deleted.includes(id));
      if (deleted.length > 0) {
        addAlert(
          `${
            deleted.length
          } files are scheduled to be permanently deleted. File IDs: ${deleted.join(
            ", "
          )}. Please refresh the page to see the results of deletion.`,
          "info"
        );
      }
      if (notDeleted.length > 0) {
        addAlert(
          `${
            notDeleted.length
          } file failed to delete. File ID: ${notDeleted.join(", ")}`,
          "error",
          15000
        );
      }
    });
    setTimeout(refreshPage, 10 * 1000);
  };
  return (
    <Button
      onClick={() => handleDelete(files)}
      disabled={files.length === 0 || disableAction}
      variant="danger"
      className="mb-2"
    >
      Delete
    </Button>
  );
}

function SelectAllFilesCheckbox({ selectedFiles, setSelectedFiles, allFiles }) {
  const [checked, setChecked] = React.useState(
    selectedFiles.length === allFiles.length && allFiles.length > 0
  );
  const onChange = () => {
    if (checked) {
      setSelectedFiles([]);
      setChecked(false);
    } else {
      setSelectedFiles(allFiles.map((row) => row.id));
      setChecked(true);
    }
  };
  return <input type="checkbox" checked={checked} onChange={onChange} />;
}

export { DeleteFilesButton, SelectAllFilesCheckbox };
