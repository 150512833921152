import moment from "moment";
import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useLoaderData, defer, useRevalidator } from "react-router-dom";

import { filtersLoader } from "~/blocks/creatives/assets/filters";
import {
  DeleteFilesButton,
  SelectAllFilesCheckbox,
} from "~/blocks/delete-files";
import { PreviewableText } from "~/blocks/file-preview/file-preview";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import { axios, extractSearch } from "~/utils";

import { AssetDropzone, GdriveCredsDropzoneGuard } from "./dropzone";
import { UploadAssetsModal } from "./upload-modal";

import "./assets.scss";

function PlayableAssetsPage() {
  return <PlayableAssetsFunctionPage />;
}

function PlayableAssetsFunctionPage() {
  const { playableAssets, playable, languages, networks } = useLoaderData();

  const revalidator = useRevalidator();

  const refreshPage = () => {
    revalidator.revalidate();
  };

  return (
    <>
      <PageHeader />
      <Row className="mb-4 pt-4">
        <Col xs={6}>
          <PlayableConceptDescriptionBlock playable={playable} />
        </Col>
        <Col xs={6}>
          <GdriveCredsDropzoneGuard>
            <AssetDropzone>
              {({ filesToUpload, removeFile, clearFiles }) =>
                !!filesToUpload.length && (
                  <UploadAssetsModal
                    filesToUpload={filesToUpload}
                    onFileRemove={removeFile}
                    onClose={(shouldRefreshPage) => {
                      clearFiles();
                      if (shouldRefreshPage) {
                        refreshPage();
                      }
                    }}
                    playable={playable}
                    languages={languages}
                    networks={networks}
                  />
                )
              }
            </AssetDropzone>
          </GdriveCredsDropzoneGuard>
        </Col>
      </Row>
      <PlayableAssetsTable data={playableAssets} refreshPage={refreshPage} />
    </>
  );
}

PlayableAssetsPage.loader = async function loader({ params, request }) {
  const search = extractSearch(request.url);
  const [assetsResponse] = await Promise.all([
    axios(`playables/concept/${params.conceptID}${search}`),
  ]);
  const [playableResponse] = await Promise.all([
    axios(`playable-concepts/${params.conceptID}`),
  ]);
  const [languagesResponse] = await Promise.all([axios("languages/")]);
  const [networksResponse] = await Promise.all([axios("networks/")]);
  const filtersPromise = filtersLoader();

  return defer({
    playableAssets: assetsResponse.data,
    playable: playableResponse.data,
    languages: languagesResponse.data,
    networks: networksResponse.data,
    filtersPromise,
  });
};

function PlayableAssetsTable({ data, refreshPage }) {
  const [selectedEndCards, setSelectedEndCards] = React.useState([]);
  const toggleRowSelection = (id) => {
    if (selectedEndCards.includes(id)) {
      setSelectedEndCards(selectedEndCards.filter((rowId) => rowId !== id));
    } else {
      setSelectedEndCards([...selectedEndCards, id]);
    }
  };

  return (
    <>
      <DeleteFilesButton
        endpoint="playables"
        files={selectedEndCards}
        refreshPage={refreshPage}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th scope="col">
              <SelectAllFilesCheckbox
                selectedFiles={selectedEndCards}
                setSelectedFiles={setSelectedEndCards}
                allFiles={data.results}
              />
            </th>
            <th scope="col" style={{ width: "4em" }}>
              ID
            </th>
            <th scope="col">Name</th>
            <th scope="col">Date</th>
            <th scope="col">Network</th>
            <th scope="col">CTA</th>
            <th scope="col">Number of clicks</th>
            <th scope="col">Change</th>
            <th scope="col">Language</th>
            <th scope="col">Google Drive Link</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((playable) => (
            <tr
              key={playable.id}
              style={{ height: "4em", verticalAlign: "middle" }}
            >
              <td>
                <input
                  type="checkbox"
                  checked={selectedEndCards.includes(playable.id)}
                  onChange={() => toggleRowSelection(playable.id)}
                />
              </td>
              <td>{playable.id}</td>
              <td>
                <PreviewableText
                  preview={playable.preview}
                  text={playable.long_name}
                />
              </td>
              <td>
                {moment.utc(playable.created_at).format("DD.MM.YYYY, HH:mm:ss")}
              </td>
              <td>{playable.network_field}</td>
              <td>{playable.cta_field}</td>
              <td>{playable.number_of_clicks}</td>
              <td>{playable.change}</td>
              <td>{playable.language_field}</td>
              <td>
                {!!playable.creative_link && (
                  <a
                    href={playable.creative_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Drive Link
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

function PlayableConceptDescriptionBlock({ playable }) {
  return (
    <Card>
      <Card.Body>
        <Row className="mb-3">
          <Row>
            <Col xs={3} style={{ fontWeight: "bold" }}>
              Date
            </Col>
            <Col xs={2} style={{ fontWeight: "bold" }}>
              App
            </Col>
            <Col xs={2} style={{ fontWeight: "bold" }}>
              Playable #
            </Col>
            <Col xs={4} style={{ fontWeight: "bold" }}>
              Playable Theme
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              {moment.utc(playable.created_at).format("DD.MM.YYYY, HH:mm:ss")}
            </Col>
            <Col xs={2}>{playable.application_code}</Col>
            <Col xs={2}>{playable.creative_number}</Col>
            <Col xs={4}>{playable.theme}</Col>
          </Row>
        </Row>
        <Row>
          <Row>
            <Col xs={3} style={{ fontWeight: "bold" }}>
              User
            </Col>
            <Col xs={4} style={{ fontWeight: "bold" }}>
              Playable Folder Link
            </Col>
          </Row>
          <Row>
            <Col xs={3}>{playable.created_by_name}</Col>
            <Col xs={4}>
              {playable.creative_folder_link === null ? (
                "-"
              ) : (
                <a
                  href={playable.creative_folder_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Drive Link
                </a>
              )}
            </Col>
          </Row>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default PlayableAssetsPage;
