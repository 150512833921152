import moment from "moment";
import React from "react";
import { Table } from "react-bootstrap";
import { useLoaderData, defer } from "react-router-dom";

import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import { axios, extractSearch } from "~/utils";

function ActionLogsPage() {
  const { actionLogs } = useLoaderData();

  return (
    <>
      <PageHeader />
      <ActionLogsTable data={actionLogs} />
    </>
  );
}

ActionLogsPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const response = await axios(`action-logs${search}`);

  return defer({
    actionLogs: response.data,
  });
};

function Status({ status }) {
  let text = "Unknown";
  if (status === "passed") {
    text = "Passed";
  }
  if (status === "failed") {
    text = "Failed";
  }
  if (status === "in_progress") {
    text = "In progress";
  }
  if (status === "test_in_progress") {
    text = "Test in progress";
  }
  if (status === "success") {
    text = "Success";
  }
  if (status === "in_queue") {
    text = "In Queue";
  }
  return <span>{text}</span>;
}

function ActionLogsTable({ data }) {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th scope="col">Date & Time</th>
            <th scope="col">User</th>
            <th scope="col">Operations Type</th>
            <th scope="col">Creative ID</th>
            <th scope="col">Message</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((actionLog) => (
            <tr key={actionLog.id}>
              <td>
                <p>{moment.utc(actionLog.created_at).format("MMMM D, YYYY")}</p>
                <p>{moment.utc(actionLog.created_at).format("HH:mm:ss")}</p>
              </td>
              <td>{actionLog.acted_by}</td>
              <td>{actionLog.action_type_label}</td>
              <td>{actionLog.creative_id_number}</td>
              <td style={{ width: "40%" }}>
                <p className="mb-0">
                  Concept name: {actionLog.concept_name || "N/A"}
                </p>
                {!!actionLog.long_name && (
                  <p className="mb-0">Creative name: {actionLog.long_name}</p>
                )}
                <p>
                  Creative link:{" "}
                  {(!!actionLog.creative_link && (
                    <a
                      href={actionLog.creative_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {actionLog.creative_link}
                    </a>
                  )) ||
                    "N/A"}
                </p>
                <p className="mb-0">
                  {!!actionLog.metadata.download &&
                    `Downloaded: ${actionLog.metadata.download || 0} %`}
                </p>
                {!!actionLog.metadata.messages && (
                  <>
                    {actionLog.metadata.messages.map((message) =>
                      message.type === "error" ? (
                        <p
                          style={{ color: "red" }}
                          className="mb-0"
                          key={`error-${Math.random()}`}
                        >
                          {message.text}
                        </p>
                      ) : (
                        <p className="mb-0" key={`text-${Math.random()}`}>
                          {message.text !== "" ? message.text : <br />}
                        </p>
                      )
                    )}
                  </>
                )}
              </td>
              <td>
                <Status status={actionLog.status} />{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

export default ActionLogsPage;
