import React from "react";
import { Button, Row, Col } from "react-bootstrap";

import Spinner from "~/components/spinner";
import { getGoogleAdsCredentials } from "~/constants";
import { loadScript, useUserData } from "~/utils";

function GoogleAdsCredsContainer({ children }) {
  const hasRefreshToken = useUserData().has_google_ads_token;
  const [loading, setLoading] = React.useState(true);
  const [client, setClient] = React.useState(null);
  const [refreshExist] = React.useState(hasRefreshToken);
  const context = React.useMemo(
    () => ({ loading, client, refreshExist }),
    [loading, client, refreshExist]
  );

  React.useEffect(() => {
    loadScript("https://accounts.google.com/gsi/client").then(() => {
      setClient(
        window.google.accounts.oauth2.initCodeClient({
          ...getGoogleAdsCredentials(),
          ux_mode: "redirect",
          include_granted_scopes: false,
        })
      );
      setLoading(false);
    });
  }, []);

  return (
    <googleAdsCredsContext.Provider value={context}>
      {children}
    </googleAdsCredsContext.Provider>
  );
}

function GoogleAdsCredsGuard({ children }) {
  const { loading, client, refreshExist } = React.useContext(
    googleAdsCredsContext
  );

  if (loading) return <Spinner />;
  if (!refreshExist)
    return (
      <>
        <Row className="justify-content-center mt-5">
          <Col xs={12} md={8}>
            <p className="text-center">
              Please authenticate Google Ads Cactus account to allow connection
              with Google Ads and video asset assignment.
            </p>
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <Button onClick={() => client.requestCode()}>
            Google Ads Authenticate
          </Button>
        </div>
      </>
    );
  if (typeof children === "function") {
    return children(refreshExist);
  }
  return (
    <tokenContext.Provider value={refreshExist}>
      {children}
    </tokenContext.Provider>
  );
}

function useGoogleAdsToken() {
  return React.useContext(tokenContext);
}

const googleAdsCredsContext = React.createContext();
const tokenContext = React.createContext();

export { GoogleAdsCredsContainer, GoogleAdsCredsGuard, useGoogleAdsToken };
