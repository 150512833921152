import {
  faCircleXmark,
  faSpinner,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";

import { useAlert } from "~/base/alerts";
import { showParsingErrorAlerts } from "~/blocks/creatives/agency";
import { GdriveCredsGuard } from "~/blocks/gdrive/credentials";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import PageHeader from "~/components/page-header";
import { axios } from "~/utils";

function LinkAgencyCreativePage() {
  return (
    <>
      <PageHeader />
      <Container>
        <GdriveCredsGuard>
          <Row>
            <p className="mt-4">
              Please provide the link to a concept folder in Google Drive to set
              up a new creative from an agency:
            </p>
          </Row>
          <LinkAgencyCreativeForm />
        </GdriveCredsGuard>
      </Container>
    </>
  );
}

const gdriveFolderLinkPattern =
  /^https:\/\/drive\.google\.com\/drive\/(?:u\/0\/)?folders\/([a-zA-Z0-9_-]+)(\?.*)?$/;
const validationSchema = Yup.object().shape({
  folder_link: Yup.string()
    .matches(gdriveFolderLinkPattern, "Invalid Google Drive folder link.")
    .required("Folder link is required"),
});

function CreativeLoadingSpinner() {
  return (
    <Col xs={{ offset: 6, span: 1 }}>
      <FontAwesomeIcon
        className="fa fa-spin fa-2xl"
        icon={faSpinner}
        color="#0d6efd"
      />
    </Col>
  );
}

function CreativeErrorLabel({ errorMessage }) {
  return (
    <div style={{ fontSize: "large", textAlign: "center" }}>
      Creative not added. {errorMessage}
      <FontAwesomeIcon
        className="fa fa-solid fa-2xl ms-3"
        icon={faCircleXmark}
        color="red"
      />
    </div>
  );
}

function CreativeSuccessLabel({ concept, created }) {
  const msg = created ? "Creative was added" : "Creative was linked before";
  return (
    <div style={{ fontSize: "large", textAlign: "center" }}>
      <div className="d-inline-flex">
        <p>{msg}</p>
        <FontAwesomeIcon
          className="fa fa-solid fa-2xl ms-3"
          icon={faCircleCheck}
          color="green"
        />
      </div>
      <div>
        {" "}
        <Link to={`/creatives/concepts/${concept.id}/assets`}>
          <Button variant="primary">Go to assets</Button>
        </Link>
      </div>
    </div>
  );
}

function getStatusElement(status, errorMessage, concept, created) {
  if (status === "loading") {
    return <CreativeLoadingSpinner />;
  }
  if (status === "error") {
    return <CreativeErrorLabel errorMessage={errorMessage} />;
  }
  if (status === "success" && concept !== null) {
    return <CreativeSuccessLabel concept={concept} created={created} />;
  }
  return "";
}

function LinkAgencyCreativeForm() {
  const navigate = useNavigate();
  const [creationStatus, setCreationStatus] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [concept, setConcept] = React.useState(null);
  const [created, setCreated] = React.useState(false);
  const addAlert = useAlert();
  const onSubmit = (values) => {
    setCreationStatus("loading");
    return axios({
      method: "POST",
      url: "agencies-upload/",
      data: values,
    })
      .then((response) => {
        setCreationStatus("success");
        setConcept(response.data.concept);
        setCreated(response.data.created);
        const parsingErrors = response.data.errors;
        if (parsingErrors.length === 0 && response.data.created) {
          navigate(`/creatives/concepts/${response.data.concept.id}/assets`);
        }
        showParsingErrorAlerts(parsingErrors, addAlert);
      })
      .catch((error) => {
        setCreationStatus("error");
        if (!error.response) {
          setErrorMessage(`Error: ${error.message}`);
          return;
        }
        const responseData = error.response.data;
        let message;
        if (responseData.errors) {
          showParsingErrorAlerts(responseData.errors, addAlert);
          message = "No valid creative files found in folder.";
        } else {
          message =
            responseData.detail !== null ? responseData.detail : responseData;
        }
        setErrorMessage(`Error: ${JSON.stringify(message)}`);
      });
  };
  const StatusElement = getStatusElement(
    creationStatus,
    errorMessage,
    concept,
    created
  );

  return (
    <>
      <Row className="my-4 d-flex align-items-center">
        <Form
          initialValues={{
            folder_link: "",
          }}
          validationSchema={validationSchema}
          syncSubmit
          onSubmit={onSubmit}
        >
          <Form.Group as={Row} className="mb-3" controlId="folder_link">
            <Form.Label column sm="2">
              Concept Folder Link:
            </Form.Label>
            <Col sm="4">
              <Form.Control
                name="folder_link"
                placeholder="Agency'c Concept Google Drive Folder"
              />
              <Form.Control.ErrorFeedback name="folder_link" />
            </Col>
          </Form.Group>
          <Row>
            <Col xs="auto">
              <SubmitButton
                className="px-4"
                icon={false}
                title="Save"
                disabled={["success", "loading"].includes(creationStatus)}
              />
            </Col>
          </Row>
        </Form>
      </Row>
      <div style={{ marginTop: "5rem" }} className="mb-3">
        {StatusElement}
      </div>
    </>
  );
}

export default LinkAgencyCreativePage;
