function getParsingErrorText(error) {
  if ("file_name" in error.errors) {
    return "Ensure filename is no more than 100 characters.";
  }
  return "";
}

function showParsingErrorAlerts(parsingErrors, addAlert) {
  parsingErrors.map((error) =>
    addAlert(
      `Failed to parse ${error.file_name}. ${getParsingErrorText(error)}`,
      "error",
      null
    )
  );
}

export { showParsingErrorAlerts };
