/*
 * The same component as react-select, but uses actual values instead if objects { value: ..., label: ... }
 */
import { useField } from "formik";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect, { components } from "react-select";

import { findByValue } from "~/utils";

import { useStatusValid } from "./error";

function Select({ name, ...props }) {
  const [{ onBlur, value }, meta, { setValue }] = useField({
    ...props,
    multiple: props.isMulti,
    name,
  });
  const isStatusValid = useStatusValid(name);
  const isInvalid = meta.touched && (!isStatusValid || meta.error);

  const styles = props.styles || {
    control: (provided) => {
      const extra = isInvalid ? { borderColor: "#dc3545" } : {};
      return {
        ...provided,
        ...extra,
      };
    },
  };

  return (
    <DetachedSelect
      {...props}
      value={value}
      onChange={setValue}
      onBlur={onBlur}
      styles={styles}
    />
  );
}

Select.propTypes = {
  isMulti: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  styles: PropTypes.shape({}),
};

Select.defaultProps = {
  isMulti: false,
  styles: null,
};

const DetachedSelect = React.forwardRef(
  (
    { value, isMulti, onChange, options, onPaste, className, ...props },
    ref
  ) => {
    const flatOptions = React.useMemo(() => flattenOptions(options), [options]);
    const innerValue = isMulti
      ? value.map((v) => findByValue(flatOptions, v))
      : findByValue(flatOptions, value);
    const handleChange = React.useCallback(
      (selection, action) => {
        if (isMulti) {
          onChange(
            selection.map((o) => o.value),
            action
          );
        } else if (action.action === "clear") {
          onChange(null, action);
        } else {
          onChange(selection.value, action);
        }
      },
      [isMulti, onChange]
    );

    const componentsToPass = onPaste ? { Input } : undefined;
    return (
      <ReactSelect
        ref={ref}
        isMulti={isMulti}
        options={options}
        value={innerValue}
        onChange={handleChange}
        onPaste={onPaste}
        components={componentsToPass}
        className={
          props.isError ? `${className} react-select-error` : className
        }
        {...props}
      />
    );
  }
);

DetachedSelect.defaultProps = {
  isMulti: false,
};

function flattenOptions(options) {
  let rv = [];
  options.forEach((o) => {
    if ("value" in o) {
      rv.push(o);
    } else if ("options" in o) {
      rv = rv.concat(o.options);
    }
  });
  return rv;
}

function Input(props) {
  const { onPaste } = props.selectProps;
  return <components.Input onPaste={onPaste} {...props} />;
}

export { DetachedSelect };
export default Select;
