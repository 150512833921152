import React from "react";
import { Row, Col } from "react-bootstrap";

import { ResetButton, FilterCol } from "~/components/filters";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { useFilters } from "~/components/page-header";
import { cachedAxios, useSearchParams } from "~/utils";

async function filtersLoader() {
  const response = await cachedAxios("/playable-concepts/filter-choices");
  return response.data;
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();
  const { setFilterOpened } = useFilters([
    "application",
    "creative_number",
    "theme",
    "created_by",
  ]);

  const initialValues = {
    application: state.application || "",
    creative_number: state.creative_number || "",
    theme: state.theme || "",
    created_by: state.created_by || "",
  };

  const onChange = (values) => {
    setState({
      application: values.application || null,
      creative_number: values.creative_number || null,
      theme: values.theme || null,
      created_by: values.created_by || null,
      page: 1,
    });
    setFilterOpened(false);
  };
  return (
    <NewPlayableFiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
    />
  );
}

function NewPlayableFiltersForm({ options, initialValues, onChange }) {
  return (
    <Form initialValues={initialValues} syncSubmit onSubmit={onChange}>
      <Row className="mb-3 justify-content-md-center">
        <FilterCol label="User" md={2}>
          <Form.Select name="created_by" options={options.created_by} />
        </FilterCol>
        <FilterCol label="App" md={2}>
          <Form.Select name="application" options={options.application} />
        </FilterCol>
        <FilterCol label="Playable #" md={2}>
          <Form.Select
            name="creative_number"
            options={options.creative_number}
          />
        </FilterCol>
        <FilterCol label="Theme" md={2}>
          <Form.Control name="theme" />
        </FilterCol>
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-end" md={12} sm={10}>
          <Col xs="auto" className="me-2">
            <ResetButton />
          </Col>
          <Col xs="auto">
            <SubmitButton icon={false} title="Filter" />
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

export { filtersLoader, PageFiltersForm, NewPlayableFiltersForm };
