import React from "react";
import { Link, useNavigation } from "react-router-dom";

import { getEnvironmentName } from "~/constants";

import logoUrl from "./logo.svg";

function Brand({ minimized }) {
  return (
    <>
      <Link
        to="/"
        className="d-flex align-items-center mx-auto mt-3 text-decoration-none"
      >
        <Logo minimized={minimized} />
      </Link>
      <EnvBadge minimized={minimized} />
    </>
  );
}

function EnvBadge({ minimized }) {
  const [shortEnv, longEnv] = getEnvironmentName();
  const bgColor = shortEnv === "PRD" ? "bg-danger" : "bg-success";
  return (
    <h6
      className={`sidebar__env-badge text-center text-uppercase mb-0 mt-3 text-white ${bgColor}`}
    >
      {minimized ? shortEnv : longEnv}
    </h6>
  );
}

function Logo({ minimized }) {
  if (minimized) {
    return <TripledotLogo width="30" height="30" className="me-2 ms-1" />;
  }
  return (
    <>
      <TripledotLogo width="30" height="30" className="me-2" />
      <h3 className="fw-bold text-dark mb-0">CACTUS</h3>
    </>
  );
}

function TripledotLogo({ ...props }) {
  const navigation = useNavigation();
  const loading = navigation.state === "loading";
  const style = loading ? { animation: "rotate 1.5s infinite linear" } : {};
  return <img style={style} alt="logo" src={logoUrl} {...props} />;
}

export default Brand;
