import moment from "moment";
import React from "react";
import { Button, Table } from "react-bootstrap";
import { useLoaderData, defer, useRevalidator } from "react-router-dom";

import { useAlert } from "~/base/alerts";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import { axios, extractSearch } from "~/utils";
import { useInterval } from "~/utils/time";

function NetworkLaunchesPage() {
  const { launches } = useLoaderData();
  const revalidator = useRevalidator();

  const refreshPage = () => {
    revalidator.revalidate();
  };
  useInterval(refreshPage, 10000);

  return (
    <>
      <PageHeader>
        <Button variant="primary" href="/network-launches/new">
          New Launch
        </Button>
      </PageHeader>
      <NetworkLaunchesTable data={launches} />
    </>
  );
}

NetworkLaunchesPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const response = await axios(`network-launches${search}`);

  return defer({
    launches: response.data,
  });
};

function ApplovinCampaignInfo({ info }) {
  const adsComponents = info.ads.map((ad, idx) => (
    <div>
      <p key={ad.id}>
        {idx + 1}. {ad.name} - ID {ad.network_id} (
        {ad.enabled ? "Enabled" : "Disabled"})
      </p>
    </div>
  ));
  return (
    <div>
      <a href={info.campaign_link} target="_blank" rel="noreferrer">
        Campaign link
      </a>
      {adsComponents}
    </div>
  );
}

function UnityCampaignInfo({ info }) {
  return (
    <div>
      <a href={info.campaign_link} target="_blank" rel="noreferrer">
        Creative packs
      </a>
      <p>Platform: {info.platform}</p>
    </div>
  );
}

function CreativeAssets({ launch }) {
  const objects = [
    { name: "End Card", data: launch.end_card },
    { name: "End Card 2", data: launch.end_card_2 },
    { name: "Playable", data: launch.playable },
    { name: "Landscape Video Asset", data: launch.landscape_video_asset },
    { name: "Portrait Video Asset", data: launch.portrait_video_asset },
  ];

  return (
    <div>
      {objects.map(
        (obj) =>
          obj.data && (
            <p key={obj.name}>
              {obj.name}: {obj.data.long_name}
            </p>
          )
      )}
    </div>
  );
}

function SyncApplovinAdsButton({ launch }) {
  const addAlert = useAlert();
  const syncAds = () =>
    axios({
      method: "POST",
      url: `applovin-launches/${launch.id}/sync/`,
    })
      .then(() => {
        addAlert("Successfully synced Applovin ads", "info");
      })
      .catch(() => {
        addAlert("Failed to sync Applovin ads", "error");
      });
  return (
    <Button variant="info" size="sm" onClick={syncAds}>
      Sync Ads
    </Button>
  );
}

function RetryUnityCampaignButton({ launch }) {
  const addAlert = useAlert();
  const syncAds = () =>
    axios({
      method: "POST",
      url: `unity-launches/${launch.id}/retry/`,
    })
      .then(() => {
        addAlert("Successfully retried Unity Campaing Launch", "info");
      })
      .catch(() => {
        addAlert("Failed to retry Unity Campaign launch", "error");
      });
  return (
    <Button variant="success" size="sm" onClick={syncAds}>
      Retry
    </Button>
  );
}

function NetworkLaunchesTable({ data }) {
  function checkNetwork(launch, networkName) {
    return launch.network.toLowerCase() === networkName;
  }
  return (
    <>
      <Table className="network-launches-table" striped bordered hover>
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">User</th>
            <th scope="col">Time</th>
            <th scope="col">Network</th>
            <th scope="col">App</th>
            <th scope="col" className="long-cell">
              Creative Assets
            </th>
            <th scope="col">Language</th>
            <th className="long-cell" scope="col">
              Campaign
            </th>
            <th className="long-cell" scope="col">
              Info
            </th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((launch) => (
            <tr key={launch.id}>
              <td>{launch.id}</td>
              <td>{launch.creator}</td>
              <td>
                {moment.utc(launch.created_at).format("MMMM D, YYYY HH:mm:ss")}
              </td>
              <td>{launch.network}</td>
              <td>{launch.application}</td>
              <td className="long-cell">
                <CreativeAssets launch={launch} />
              </td>
              <td>{launch.language}</td>
              <td className="long-cell">{launch.campaign_name}</td>
              <td className="long-cell">
                {checkNetwork(launch, "applovin") && (
                  <ApplovinCampaignInfo info={launch.info} />
                )}
                {checkNetwork(launch, "unity") && (
                  <UnityCampaignInfo info={launch.info} />
                )}
              </td>
              <td>{launch.status}</td>
              <td>
                {checkNetwork(launch, "applovin") && (
                  <SyncApplovinAdsButton launch={launch} />
                )}
                {/* temporary hide this feature */}
                {checkNetwork(launch, "you-shall-not-pass") && (
                  <RetryUnityCampaignButton launch={launch} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

export default NetworkLaunchesPage;
